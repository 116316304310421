@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');

* {
    font-family: 'Work Sans', sans-serif;
}

/* h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: white;
} */

.text--center {
    text-align: center;
}