:root {
    /*  Colours  */
    --lightest: rgb(255, 255, 255);
    --light: whitesmoke;
    --highlight: rgb(10, 122, 191);
    
    --dark: rgb(43, 41, 25);
    --darkest: rgb(23, 23, 23);

    /*  Highlight Colours  */
    --highlight-hover: rgb(31, 145, 216);

    /*  Layout  */
    --site-width: 1200px;
    --site-gutter: 5em;
    --site-section-padding: 5em;

    /*  Misc Styling  */
    --border-radius: 0.55em;
    --box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.15);
    --transition--hover: 225ms ease;
}

@media (max-width: 800px) {
    :root {
        --site-gutter: 1em;
        --site-section-padding: 3em;
    }
}