.section--dark {
    color: white;
    background: var(--dark);
}

.section--darkest {
    color: white;
    background: var(--darkest);
}

.section--light {
    color: black;
    background: var(--light);
}

.section--lightest {

}

#tracks {
    background-image:
    linear-gradient(to top, rgba(23, 23, 23, 0.75), rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.75)),
    url('https://images.unsplash.com/photo-1496208612508-eb52fba7d94e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}