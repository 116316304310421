#hero {
    color: white;
    background-image:
    linear-gradient(to top, rgba(23, 23, 23, 0.75), rgba(23, 23, 23, 0.5), rgba(23, 23, 23, 0.75)),
    url('https://images.unsplash.com/photo-1563330232-57114bb0823c?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80');
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    min-height: 75vh;
    /* border-bottom: 10px solid rgb(249, 249, 66); */
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    justify-content: center;
}